import { CONFIG_PARAMS_GET } from './config/fetch';

async function getIsCampana(email: string) {
  try {
    const res = await fetch(
      `/api/v1/valid-is-campana?email=${email}`,
      CONFIG_PARAMS_GET,
    );

    return res.json();
  } catch (err) {
    return null;
  }
}
export default getIsCampana;
