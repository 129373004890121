/* eslint-disable react/no-unknown-property */
/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import TagManager from 'react-gtm-module';
import Head from 'next/head';
import Error from 'next/error';
import { Router } from 'routes';
import { ApolloProvider } from '@apollo/client';

import { useApollo } from 'lib/apollo/withApollo';
import { IOSView, MobileOnlyView } from 'react-device-detect';

import { LoadingBackdrop } from '@olxbr/design-system';

import { initGA, pageView } from 'lib/ga';
import Layout from '../components/templates/layout';

import isServer from '../util/is-server';
import reportWebVitals from '../util/reportWebVitals';

import { UserProvider } from '../components/context/user-context';
import { ChatProvider } from '../components/context/salesforce-chat';

import '../styles/app.scss';

import '@olxbr/ds-web/lib/styles.css';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }
`;
const theme = {
  colors: {
    primary: 'red',
  },
};

const MyApp = ({ Component, pageProps }) => {
  const [loading, setLoading] = useState(false);
  const apolloClient = useApollo(pageProps.initialApolloState);

  const startLoading = () => {
    setLoading(true);
  };

  const stopLoading = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (!isServer()) {
      if (process.env.isProd) {
        TagManager.initialize({
          gtmId: process.env.gtmTrackingId,
          dataLayerName: 'gtmDataLayer',
        });
      }

      Router.events.on('routeChangeStart', startLoading);
      Router.events.on('routeChangeComplete', stopLoading);
      Router.events.on('routeChangeError', stopLoading);
    }

    if (process.env.isProd) {
      initGA();
      pageView();
      reportWebVitals();
      Router.events.on('routeChangeComplete', pageView);
    }

    return () => {
      if (!isServer()) {
        Router.events.off('routeChangeStart', startLoading);
        Router.events.off('routeChangeComplete', stopLoading);
        Router.events.off('routeChangeError', stopLoading);
      }

      if (process.env.isProd) {
        Router.events.off('routeChangeComplete', pageView);
      }
    };
  }, []);

  const modifiedPageProps = { ...pageProps };

  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <ChatProvider>
          <ApolloProvider client={apolloClient}>
            <Head>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
              <script
                dangerouslySetInnerHTML={{
                  __html: `
          (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:1425418,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
                }}
              />

              <link
                href="https://static.olx.com.br/design-system/olx-reset.min.css"
                rel="stylesheet"
              />
              <link
                rel="stylesheet"
                href="https://static.olx.com.br/design-system/ds-tokens.css"
              />
              <link rel="preconnect" href="https://fonts.googleapis.com" />
              <link
                rel="preconnect"
                href="https://fonts.gstatic.com"
                crossOrigin=""
              />
              <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap"
              />
              <link
                href="https://static.olx.com.br/design-system/olx-reset.min.css"
                rel="preload"
                as="style"
              />
              <link
                href="https://static.olx.com.br/design-system/olx-reset.min.css"
                rel="stylesheet"
              />

              <link
                href="https://dsapega.olx.com.br/olx/v1/ds-tokens.css"
                rel="preload"
                as="style"
              />
              <link
                href="https://dsapega.olx.com.br/olx/v1/ds-tokens.css"
                rel="stylesheet"
              />

              <link rel="preconnect" href="https://fonts.googleapis.com" />
              <link
                rel="preconnect"
                href="https://fonts.gstatic.com"
                crossorigin
              />
              <link
                href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700&display=swap"
                rel="stylesheet"
              />
            </Head>
            <MobileOnlyView>
              <IOSView>
                <style global>
                  {`
            #cookie-notice-box {
              bottom: 80px !important;
            }`}
                </style>
              </IOSView>
            </MobileOnlyView>
            <Layout error={pageProps.errorCode}>
              <GlobalStyle />
              {loading && <LoadingBackdrop />}
              {pageProps.errorCode ? (
                <Error statusCode={pageProps.errorCode} />
              ) : (
                <Component {...modifiedPageProps} />
              )}
            </Layout>
          </ApolloProvider>
        </ChatProvider>
      </UserProvider>
    </ThemeProvider>
  );
};

export default MyApp;
